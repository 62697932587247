
var app = angular.module('app', [
    'ngFileUpload',
    'ckeditor',
    'angular-growl',
    'ngSanitize'
]).config(['growlProvider', function (growlProvider) {
    growlProvider.globalTimeToLive(3000);
}]);

app.directive('ngConfirm', [function() {
    return {
        restrict: 'A',
        link: function(scope, element, attrs) {
            element.bind('click', function() {
                var condition = scope.$eval(attrs.ngConfirmCondition);
                if(condition || attrs.ngConfirmCondition === undefined){
                    var message = attrs.ngConfirmMessage || 'Bevestig';
                    if (message && confirm(message)) {
                        scope.$apply(attrs.ngConfirmClick);
                    }
                }
                else{
                    scope.$apply(attrs.ngConfirmClick);
                }
            });
        }
    }
}]);
